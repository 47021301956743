const Noodl = require('@noodl/noodl-sdk');
import { QueryClient, QueryClientProvider, useQuery, useMutation } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { useState, useEffect, useRef } from 'react'; import { Button } from 'primereact/button'; import { Tree } from 'primereact/tree';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace'; import { InputText } from 'primereact/inputtext';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import "primereact/resources/primereact.min.css";
import "../primereact-sass-theme/themes/rolder/theme.scss";
import "/node_modules/primeflex/primeflex.css"
import "primeicons/primeicons.css";
import "./index.css";

var FlatToNested = require('flat-to-nested');
var flatToNested = new FlatToNested({ id: 'id', parent: 'parentId', children: 'children' });
const queryClient = new QueryClient();

const PrimeTree = (props) => {
    const debug = props.debug
    const className = props.className
    const parseClass = Parse.Object.extend(className)
    const parseQuery = new Parse.Query(parseClass);
    parseQuery.limit(10000)


    const baseQuery = async () => {
        if (debug > 0) console.time(className + '_parseFetch')
        const response = await parseQuery.find()
        if (debug > 0) console.timeEnd(className + '_parseFetch')
        let noodlItems = []
        response.forEach(r => {
            noodlItems.push(Noodl.Object.create(Object.assign({ id: r.id, key: r.id }, r.attributes)))
        })
        const nodes = flatToNested.convert(noodlItems.map(n => n.toJSON())).children
        setNodes(nodes)
        if (debug > 1) console.log(className + '_items', noodlItems)
        return { parseItems: response, noodlItems: noodlItems, nodes: nodes }
    }

    const { isFetching, data } = useQuery({
        queryKey: [
            'Parse' + className,
            parseQuery,
        ],
        queryFn: baseQuery,
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        staleTime: 360000,
    })

    const [nodes, setNodes] = useState(data?.nodes);

    const nameInputRef = useRef(null);
    const createNameRef = useRef(null);
    const [currentNode, setCurrentNode] = useState();

    useEffect(() => {
        const el1 = nameInputRef.current;
        if (el1) {
            el1.name = currentNode.name
            el1.node = currentNode.node
        }
        const el2 = createNameRef.current;
        if (el2) {
            el2.name = currentNode.name
            el2.node = currentNode.node
        }
    }, [currentNode]);

    const updateName = useMutation({
        mutationFn: async () => {
            const name = nameInputRef.current.name
            const node = nameInputRef.current.node
            if (name) {
                const parseItem = data.parseItems.find(d => d.id === node.id)
                const noodlItem = Noodl.Objects[node.id]
                parseItem.set('name', name)
                noodlItem.name = name
                if (debug > 0) console.time(className + '_parseUpdate')
                const response = await parseItem.save()
                if (debug > 0) console.timeEnd(className + '_parseUpdate')
                queryClient.invalidateQueries()
                return response
            }
        },
    })

    const create = useMutation({
        mutationFn: async () => {
            const name = createNameRef.current.name
            const node = createNameRef.current.node
            if (name) {
                const parseItem = new parseClass()
                parseItem.set('name', name)
                parseItem.set('parentId', node.id)
                parseItem.set('level', node.level + 1)
                const sourceQuery = new Parse.Query(Parse.Object.extend('Source'));
                sourceQuery.equalTo('name', 'Техэлектроснаб')
                const sources = await sourceQuery.find()
                parseItem.set('sourceId', sources[0].id)
                await parseItem.save()
                let ancestors = [...node.ancestors]
                ancestors.unshift(parseItem.id)
                parseItem.set('ancestors', ancestors)
                await parseItem.save()
                queryClient.invalidateQueries()
                return parseItem
            }
        },
        onSuccess: (parseItem) => {
            data.parseItems.push(parseItem)
            data.noodlItems.push(Noodl.Object.create(Object.assign({ id: parseItem.id, key: parseItem.id }, parseItem.attributes)))
            setNodes(flatToNested.convert(data.noodlItems.map(n => n.toJSON())).children)
        }
    })

    const accept = () => {
        const name = createNameRef.current.value
        const node = createNameRef.current.node
        create.mutate({ node: node, name: name })
    };

    const reject = () => { };

    const confirm = (event, node) => {
        confirmPopup({
            target: event.currentTarget,
            message: <InputText ref={createNameRef} onChange={(e) => setCurrentNode({ node: node, name: e.target.value })} placeholder="Название подкатегории" />,
            acceptLabel: "Добавить",
            rejectLabel: "Отмена",
            accept,
            reject
        });
    };

    const nodeTemplate = (node) => {
        return (
            <div className="flex flex-row align-items-center">
                <Inplace closable closeIcon="pi pi-check" onClose={() => updateName.mutate()}>
                    <InplaceDisplay>{node.name}</InplaceDisplay>
                    <InplaceContent>
                        <InputText ref={nameInputRef} defaultValue={node.name} onChange={(e) => setCurrentNode({ node: node, name: e.target.value })} autoFocus />
                    </InplaceContent>
                </Inplace>
                <Button icon="pi pi-plus" aria-label="Добавить подкатегорию" onClick={(e) => { confirm(e, node) }} rounded outlined raised className="ml-2" />
            </div>
        )
    }


    const createNew = useMutation({
        mutationFn: async (name) => {
            const parseItem = new parseClass()
            parseItem.set('name', name)
            parseItem.set('level', 1)
            const sourceQuery = new Parse.Query(Parse.Object.extend('Source'));
            sourceQuery.equalTo('name', 'Техэлектроснаб')
            const sources = await sourceQuery.find()
            parseItem.set('sourceId', sources[0].id)
            await parseItem.save()
            parseItem.set('ancestors', [parseItem.id])
            await parseItem.save()
            queryClient.invalidateQueries()
            return parseItem
        },
        onSuccess: (parseItem) => {
            data.parseItems.push(parseItem)
            data.noodlItems.push(Noodl.Object.create(Object.assign({ id: parseItem.id, key: parseItem.id }, parseItem.attributes)))
            setNodes(flatToNested.convert(data.noodlItems.map(n => n.toJSON())).children)
        }
    })

    const createNewNameRef = useRef(null);
    const [newItemName, setNewItemName] = useState();

    useEffect(() => {
        const el1 = createNewNameRef.current;
        if (el1) {
            el1.name = newItemName
        }
    }, [newItemName]);

    const accept2 = () => {
        const name = createNewNameRef.current.name
        if (name) createNew.mutate(name)
    };

    const confirm2 = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: <InputText ref={createNewNameRef} onChange={(e) => setNewItemName(e.target.value)} placeholder="Название категории 1-го уровня" className="w-17rem" />,
            acceptLabel: "Добавить",
            rejectLabel: "Отмена",
            accept: accept2,
            reject
        });
    };

    const headerTemplate = () => {
        return <Button icon="pi pi-plus" aria-label="Добавить категорию 1-го уровня" onClick={(e) => confirm2(e)} rounded outlined raised className="m-2 ml-3" />
    }

    return (
        <>
            <ConfirmPopup />
            <Tree value={nodes} loading={isFetching} className="p-2 w-5 shadow-2" nodeTemplate={nodeTemplate} header={headerTemplate} />
        </>
    )
};

const TreeWithReactQueryProvider = (props) => {
    // get debug param
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.get('debug')) { props.debug = parseInt(urlParams.get('debug')) }

    return (
        <QueryClientProvider client={queryClient}>
            <PrimeTree {...props} />
            <ReactQueryDevtools />
        </QueryClientProvider>
    )
};

const TreeNode = Noodl.defineReactNode({
    name: 'Tree v1.0.0',
    getReactComponent() {
        return TreeWithReactQueryProvider;
    },
    inputProps: {
        className: {
            type: {
                name: "enum",
                enums: ['Section', 'SourceSection'],
            },
            group: "Params",
            displayName: "Class",
            default: 'Product',
        },
    }
})


Noodl.defineModule({
    reactNodes: [TreeNode],
    nodes: [],
    setup() { }
});